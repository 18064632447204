import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RadiusBasedPostCode from 'components/radius-based-postcode';
import LeadCard from 'components/shared/LeadCard';
import OfferBanner from 'components/shared/OfferBanner';
import { EVENT_ACTION, EVENT_CATEGORY, useUserAnalytics } from 'context/UserAnalytics';
import { ILeadSettingsRequest } from 'contracts/requests/ILeadSettingsRequest';
import { ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';
import { DaySchedule, getDefaultScheduleData } from 'contracts/spotdif/DaySchedule';
import { LeadDetails } from 'contracts/spotdif/LeadDetails';
import { IOnboardingStepComponent } from 'contracts/view-models/IOnboardingStepComponent';
import { Form, FormikProvider, useFormik } from 'formik';
import usePostalCodeValidation from 'hooks/usePostalCodeValidation';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Helmet } from 'react-helmet';

import './style.scss';
import {
    usePostLeadSettingDetailsMutation,
    useUpdateLeadSettingDetailsMutation,
} from 'redux/services/spotdif/onBoarding';
import * as Yup from 'yup';

import useAuthentication from '../../hooks/useAuthentication';
import MDButton from '../themed/MDButton';
import MDInput from '../themed/MDInput';
import LeadCoverage from './LeadCoverage';
import LeadHeader from './LeadHeader';

interface ILeadSettingsProps extends IOnboardingStepComponent {}

export interface IResultPostCode {
    county: string;
    key: string;
    postalCode: string[];
}

const LeadSettings: React.ForwardRefExoticComponent<React.RefAttributes<ILeadSettingsProps>> = forwardRef(
    (props, ref) => {
        const { enqueueSnackbar } = useSnackbar();
        const { user } = useAuthentication();
        const { userLeadsDetailsId: leadDetails, userServiceId } = user;
        const { trackEvent } = useUserAnalytics();
        const [isRadiusBasedPostPicker, setIsRadiusBasedPostPicker] = useState(true);

        const postCodeRegex = usePostalCodeValidation(user?.currency);

        useEffect(() => {
            if (!user.hasOnboarded) {
                trackEvent(user.isStepCompleted('leadDetails') ? EVENT_ACTION.COMPLETED : EVENT_ACTION.INITIATED, {
                    category: EVENT_CATEGORY.ONBOARDING_LEAD_DETAILS,
                    label: EVENT_CATEGORY.ONBOARDING_LEAD_DETAILS,
                });
            }
        }, []);

        const initState: Partial<LeadDetails> = {
            daily: leadDetails?.daily || 0,
            leadSchedule: leadDetails?.leadSchedule || getDefaultScheduleData(),
            postCodeTargettingList: leadDetails?.postCodeTargettingList || [],
            criteria: userServiceId?.criteria ?? [],
            isRadiusPostCode: true,
            postCodeList:
                leadDetails?.postCodeList?.length > 0 ? leadDetails?.postCodeList : [{ postcode: '', miles: '' }],
        };

        useEffect(() => {
            if (leadDetails) {
                const checkRadius = leadDetails?.type === 'map' ? false : true;
                formik.setValues({
                    daily: leadDetails?.daily || 0,
                    leadSchedule: leadDetails?.leadSchedule || getDefaultScheduleData(),
                    postCodeTargettingList: leadDetails?.postCodeTargettingList || [],
                    criteria: userServiceId?.criteria ?? [],
                    isRadiusPostCode: checkRadius,
                    postCodeList:
                        leadDetails?.postCodeList?.length > 0
                            ? leadDetails?.postCodeList
                            : [{ postcode: '', miles: '' }],
                });
            }
        }, [leadDetails, userServiceId]);

        const [createLeadSettingsTrigger, { isLoading: isCreatingLeadSettings }] = usePostLeadSettingDetailsMutation();
        const [updateLeadSettingsTrigger, { isLoading: isUpdatingLeadSettings }] =
            useUpdateLeadSettingDetailsMutation();

        const createLeadSettingsForUser = async (data: ILeadSettingsRequest) => {
            createLeadSettingsTrigger(data)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('Lead Settings Added Successfully', { variant: 'success' });
                })
                .catch((err) => {
                    enqueueSnackbar('Something Went Wrong', { variant: 'error' });
                });
        };

        const updateLeadSettingsForUser = async (data: ILeadSettingsRequest) => {
            updateLeadSettingsTrigger(data)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('Leads Settings Updated Successfully', { variant: 'success' });
                })
                .catch((err) => {
                    enqueueSnackbar('Something Went Wrong', { variant: 'error' });
                });
        };

        const validationSchema = Yup.object().shape({
            isRadiusPostCode: Yup.boolean(),

            daily: Yup.string()
                .test({
                    name: 'onlyNumber',
                    message: 'The number of leads per day must be a number between 1 and 10000',
                    test: (value) => {
                        // Must be an integer number
                        return Number.isInteger(Number(value)) && Number(value) > 0 && Number(value) <= 10000;
                    },
                })
                .required('We need you to set the number of leads per day'),

            criteria: Yup.array().of(
                Yup.object().shape({
                    criteriaType: Yup.string().required('Criteria type is required .'),
                    condition: Yup.string().required('Criteria condition is required'),
                }),
            ),
            postCodeTargettingList: Yup.array().when('isRadiusPostCode', {
                is: false,
                then: (schema) =>
                    Yup.array()
                        .of(
                            Yup.object().shape({
                                postalCode: Yup.array()
                                    .min(1, 'At Least One required')
                                    .required('Enter at least one postcode.'),
                            }),
                        )
                        .min(1, 'At Least One required')
                        .required('Select at least one areacode'),
            }),
            postCodeList: Yup.array().when('isRadiusPostCode', {
                is: true,
                then: (schema) =>
                    Yup.array().of(
                        Yup.object().shape({
                            postcode: Yup.string()
                                .required(user?.currency === ECurrency.USD ? 'Enter a zipcode' : 'Enter a postcode')
                                .matches(
                                    postCodeRegex,
                                    user?.currency === ECurrency.USD
                                        ? 'Enter a valid zipcode'
                                        : 'Enter a valid postcode',
                                ),
                            miles: Yup.string().required('Select miles'),
                        }),
                    ),
            }),
        });

        const formik = useFormik<Partial<LeadDetails>>({
            initialValues: initState,
            validationSchema: validationSchema,
            onSubmit: (values: ILeadSettingsRequest) => {
                const { postCodeTargettingList, postCodeList, ...restValues } = values;

                const leadSettingValues: ILeadSettingsRequest = {
                    ...restValues,
                    userId: user._id,
                    leadSchedule: values.leadSchedule
                        // .filter((obj) => obj.isActive)
                        .map((item: DaySchedule) => {
                            return new DaySchedule({
                                day: item.day,
                                openTime: item.openTime.format('HH:mm'),
                                closeTime: item.closeTime.format('HH:mm'),
                            });
                        }),

                    type: isRadiusBasedPostPicker ? 'radius' : 'map',
                };

                if (isRadiusBasedPostPicker) {
                    const postcodes = postCodeList?.map((item) => item?.postcode);
                    const hasDuplicates = new Set(postcodes).size !== postcodes?.length;

                    if (hasDuplicates) {
                        // Show error in snackbar
                        enqueueSnackbar('Duplicate postcodes are not allowed.', { variant: 'error' });
                        return;
                    }
                    leadSettingValues.postCodeList = postCodeList;
                } else {
                    leadSettingValues.postCodeTargettingList = postCodeTargettingList;
                }

                leadDetails?.daily && user && user?.userLeadsDetailsId
                    ? updateLeadSettingsForUser({
                          _id: user?.userLeadsDetailsId?._id,
                          ...leadSettingValues,
                      })
                    : createLeadSettingsForUser(leadSettingValues);
            },
        });

        const handleValidation = useCallback(() => {
            return formik.validateForm().then((d) => {
                const isFormValid = Object.keys(d).length === 0;
                formik.handleSubmit();
                if (!isFormValid && Object.keys(d).length > 0) {
                    window.scrollTo({ top: 0, left: 0 });
                }
                return isFormValid;
            });
        }, [formik]);

        useImperativeHandle(ref, () => ({
            handleValidation,
        }));

        const handleToggle = (value) => {
            setIsRadiusBasedPostPicker(value);
            formik.setFieldValue('isRadiusPostCode', value);
        };

        useEffect(() => {
            if (user?.userLeadsDetailsId?.type === 'map') {
                setIsRadiusBasedPostPicker(false);
            } else {
                setIsRadiusBasedPostPicker(true);
            }
        }, [user?.userLeadsDetailsId?.type]);

        const handleOfferBannerClick = () => {
            const subject = encodeURIComponent("I'm interested in more than 50 leads per day.");
            const body = encodeURIComponent(
                'Hi,\n\nI am interested in purchasing more than 50 leads per day for my business.',
            );
            const mailtoLink = `mailto:hello@spotdif.com?cc=chelsea@nmg.group&subject=${subject}&body=${body}`;

            // Open default email client
            window.location.href = mailtoLink;
        };

        return (
            <FormikProvider value={formik}>
                <Helmet>
                    <title>Lead</title>
                </Helmet>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleValidation();
                    }}
                >
                    <div className="tab-user-content">
                        <div className="lead-quantity-wrap">
                            <div className="tab-user-content-card lead-quantity-input">
                                <div className="full-width-field">
                                    <MDInput
                                        type="text"
                                        label="Daily Lead Limit (Plus up to 50% Extra)"
                                        onBlur={formik.handleBlur}
                                        id="daily"
                                        name="daily"
                                        value={formik.values.daily}
                                        placeholder="5"
                                        autoComplete="daily"
                                        onChange={formik.handleChange}
                                        error={formik.touched.daily && Boolean(formik.errors.daily)}
                                        helperText={formik.touched.daily && formik.errors.daily}
                                        inputProps={{ maxLength: 5 }}
                                    />
                                    <p className="lead-mail-text">
                                        Note: This quantity is used as a guide only. On days of higher volume, you may
                                        receive up to 1.5x this amount.
                                    </p>
                                </div>
                            </div>

                            <LeadHeader />
                        </div>
                        <OfferBanner
                            icon={<AutoAwesomeIcon className="offer-icon-sparkle" />}
                            title="Looking for more than 50 leads per day? "
                            buttonText="Get in touch"
                            onButtonClick={handleOfferBannerClick}
                        />
                        <div className="leadSettingsCardWrapper">
                            <div className="d-flex justify-space-between lead-area-text-wrapper">
                                <h6>Lead Area</h6>

                                {user.currency !== ECurrency.USD && (
                                    <p>
                                        Need to be more specific with your lead areas?{' '}
                                        {isRadiusBasedPostPicker ? (
                                            <span className="text-color-orange" onClick={() => handleToggle(false)}>
                                                Use the map picker instead
                                            </span>
                                        ) : (
                                            <span className="text-color-orange" onClick={() => handleToggle(true)}>
                                                Use the radius selector
                                            </span>
                                        )}
                                    </p>
                                )}
                            </div>

                            <div className="tab-user-content-card">
                                <div className="leadAreaTab">
                                    {isRadiusBasedPostPicker ? (
                                        <RadiusBasedPostCode currencyCode={user?.currency} />
                                    ) : (
                                        <LeadCoverage field={'postCodeTargettingList'} />
                                    )}

                                    {formik.touched.postCodeTargettingList && formik.errors.postCodeTargettingList && (
                                        <p className="error-text input-error-text">Select atleast one area code</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className="leadSettingsHrsWrapper">
                            <h6>Lead Days</h6>
                            <p className="lead-area-text">
                                Select the days you would like to receive leads. We recommend choosing your business
                                working days.
                            </p>
                            <div className="tab-user-content-card">
                                <BusinessOpeningHours fieldName="leadSchedule" isHoursVisible={false} />
                                {formik.touched.leadSchedule?.length > 0 && formik.errors.leadSchedule?.length > 0 && (
                                    <p className="error-text input-error-text">At least one day is required.</p>
                                )}
                            </div>
                        </div> */}
                        {/* <div className="leadSettingsHrsWrapper">
                            <LeadCriteriaForm formik={formik}/>
                        </div> */}
                        {user?.hasOnboarded && (
                            <div className="form-footer d-flex pt-3">
                                <MDButton
                                    className="spotdif-signup-Button"
                                    type="submit"
                                    disabled={isCreatingLeadSettings || isUpdatingLeadSettings}
                                    isLoading={isCreatingLeadSettings || isUpdatingLeadSettings}
                                >
                                    Save changes
                                </MDButton>
                            </div>
                        )}
                    </div>
                </Form>
            </FormikProvider>
        );
    },
);

export default LeadSettings;
