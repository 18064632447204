/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

// @mui material components
import List from '@mui/material/List';
import classNames from 'classnames';
import LoggedInUserInfo from 'components/shared/LoggedInUserInfo';

import './style.scss';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/themed/MDBox';
import MDTypography from 'components/themed/MDTypography';

import { setMiniSidenav, setTransparentSidenav, setWhiteSidenav, useMaterialUIController } from 'context/ThemeContext';
import React, { ReactNode, useEffect, useState } from 'react';

// react-router-dom components
import { NavLink, NavLink as RouterLink, useLocation } from 'react-router-dom';
import { generateCollapseName } from 'utils/text-helpers';
import menuIcon from './../../../assets/images/icons/menu-icon-new.png';

import SidenavCollapse from './SidenavCollapse';
import SidenavItem from './SidenavItem';
import SidenavList from './SidenavList';

// Custom styles for the Sidenav
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';

export interface ISidebarRouteItem {
    [key: string]:
        | ReactNode
        | string
        | Array<{
        module: string,
        permission: string,
    }> | ISidebarRouteItem[]
        | (() => void)
        | {
        [key: string]:
            | ReactNode
            | string
            | {
            [key: string]: ReactNode | string;
        }[];
    }[];
}

// Declaring props types for Sidenav
interface ISidebarProps {
    color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
    brand?: string;
    brandName: string;
    routes: ISidebarRouteItem[];

    [key: string]: any;
}

function Sidenav({ color, brand, brandName, routes, ...rest }: ISidebarProps): JSX.Element {
    const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    const location = useLocation();
    const { pathname } = location;
    const collapseName = generateCollapseName(pathname);

    const items = pathname.split('/').slice(1);
    const itemParentName = items[1];
    const itemName = items[items.length - 1];

    let textColor:
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'error'
        | 'dark'
        | 'white'
        | 'inherit'
        | 'text'
        | 'light' = 'white';

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = 'dark';
    } else if (whiteSidenav && darkMode) {
        textColor = 'inherit';
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        setOpenCollapse(collapseName);
        setOpenNestedCollapse(itemParentName);
    }, []);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener('resize', handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse: any) => {
        const template = collapse.map(({ name, route, key, href }: any) =>
            href ? (
                <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                    <SidenavItem name={name} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} style={{ textDecoration: 'none' }}>
                    <SidenavItem name={name} active={route === pathname} nested />
                </NavLink>
            ),
        );

        return template;
    };
    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses: any) =>
        collapses.map(({ name, collapse, route, href, key, icon }: any) => {
            let returnValue;

            if (collapse) {
                returnValue = (
                    <SidenavItem
                        key={key}
                        color={color}
                        name={name}
                        active={key === itemParentName ? 'isParent' : false}
                        open={openNestedCollapse === key}
                        onClick={({ currentTarget }: any) =>
                            openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
                                ? setOpenNestedCollapse(false)
                                : setOpenNestedCollapse(key)
                        }
                    >
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                );
            } else {
                returnValue = href ? (
                    <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                        <SidenavItem color={color} name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink to={route} key={key} style={{ textDecoration: 'none' }}>
                        <SidenavItem color={color} name={name} active={key === itemName} icon={icon} />
                    </NavLink>
                );
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>;
        });

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(
        ({ type, name, onClick, icon, title, collapse, noCollapse, key, href, route }: any) => {
            let returnValue;

            if (type === 'collapse') {
                if (href) {
                    returnValue = (
                        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                            <SidenavCollapse
                                name={name}
                                icon={icon}
                                active={key === collapseName}
                                noCollapse={noCollapse}
                            />
                        </Link>
                    );
                } else if (noCollapse && route) {
                    returnValue = (
                        <NavLink to={route} key={key}>
                            <SidenavCollapse
                                name={name}
                                icon={icon}
                                noCollapse={noCollapse}
                                active={key === collapseName}
                            >
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        </NavLink>
                    );
                } else {
                    returnValue = (
                        <a rel="noreferrer" onClick={(e) => e.preventDefault()} className="nested-links-wrapper">
                            <SidenavCollapse
                                key={key}
                                name={name}
                                icon={icon}
                                active={key === collapseName}
                                open={openCollapse === key}
                                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
                            >
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        </a>
                    );
                }
            } else if (type === 'title') {
                returnValue = (
                    <MDTypography
                        key={key}
                        color={textColor}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                        {title}
                    </MDTypography>
                );
            } else if (type === 'divider') {
                returnValue = (
                    <Divider
                        key={key}
                        light={
                            (!darkMode && !whiteSidenav && !transparentSidenav) ||
                            (darkMode && !transparentSidenav && whiteSidenav)
                        }
                    />
                );
            } else if (type === 'app-route') {
                returnValue = (
                    <RouterLink to={route} key={key} end={false} rel="noreferrer">
                        <SidenavCollapse
                            name={name}
                            icon={icon}
                            active={key === collapseName}
                            noCollapse={noCollapse}
                        />

                    </RouterLink>
                );
            } else if (type === 'app-button') {
                returnValue = (
                    <a
                        href="!#"
                        key={key}
                        onClick={(e) => {
                            e.preventDefault();
                            onClick();
                        }}
                    >
                        <SidenavCollapse
                            name={name}
                            icon={icon}
                            active={key === collapseName}
                            noCollapse={noCollapse}
                        />
                    </a>
                );
            }

            return returnValue;
        },
    );

    const handleMiniSidenav = () => {
        setMiniSidenav(dispatch, !miniSidenav);
    };

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            className={classNames('spotdif-sidebar', 'spotdif-sidebar--main')}
            ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
        >
            <MDBox pt={3} pb={1} px={4} display="flex" textAlign="center" className="sidebar-logo-header">
                <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                    {brand && <MDBox component="img" src={brand} alt="Brand" />}
                    <MDBox width={!brandName && '100%'} sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}>
                        <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                            {brandName}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                {/* <MDTypography variant="h6" color="secondary">
          <Icon sx={{ fontWeight: 'bold', color: 'white' }}>close</Icon>
        </MDTypography> */}
                <div className="sidebar-toggle-action">
                    <MDBox p={1.625} onClick={handleMiniSidenav} className="toggle-side-nav" sx={{ cursor: 'pointer' }}>
                        <img src={menuIcon} alt="toggle_icon" />
                    </MDBox>
                    {/* <MDBox
            p={1.625}
            onClick={closeSidenav}
            className="close-side-nav"
            sx={{ cursor: "pointer" }}
          >
          <img src={expandIcon} alt="toggle_icon" />
          </MDBox> */}
                </div>
            </MDBox>
            <div className="sidebar-menu-logged-wrapper">
                <List className="sidebar-route-list">{renderRoutes}</List>
                <LoggedInUserInfo />
            </div>
        </SidenavRoot>
    );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
    color: 'info',
    brand: '',
};

export default Sidenav;
