import React, { useCallback, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useSnackbar } from 'notistack';
import MDBox from 'components/themed/MDBox';
import { useLoginUserMutation } from 'redux/services/spotdif';

import { Icon, IconButton, InputAdornment } from '@mui/material';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import MDButton from 'components/themed/MDButton';
// import signUpmain from 'assets/images/sign-up-main.svg';
import signUpmain from 'assets/images/sign-up-new.svg';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './style.scss';

import { SIGNINV2, SIGNUPV2 } from 'components/layout/brandingLayout/CONST';
import { useRegexPatterns } from 'hooks/useRegexPatterns';

const SignIn: React.FC = () => {
    const [loginUser, { isLoading }] = useLoginUserMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { emailRegex } = useRegexPatterns();

    const initialValues = {
        email: '',
        password: '',
    };

    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email required *')
            .email('Must be a valid email')
            .matches(emailRegex, 'Enter a valid email'),
        password: Yup.string().required('Password required *'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await loginUser(values)
                .unwrap()
                .then((res) => {
                    enqueueSnackbar('Welcome', { variant: 'success', key: 'user-auth' });
                    window.location.reload(); // FUTURE: replace with redirect and appropriate state management
                })
                .catch((error) => {
                    enqueueSnackbar(error?.data?.error?.message, { variant: 'error', key: 'user-auth' });
                });
        },
    });

    const handleClickShowPassword = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    return (
        <>
            <Helmet>
                <title>SpotDif</title>
                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href={`${process.env.REACT_APP_WEBSITE_URL}/login`} />
            </Helmet>
            <div className="signup-wrapper-new">
                <div className="left-wrapper">
                    <h1 className="signup-heading signin-heading">
                        <p>
                            Get <span>qualified leads</span> for your business
                        </p>
                    </h1>
                    <p className="signup-para">
                        Unlock <b>exceptional leads</b> without any membership fees, utilising our
                        <b> user-friendly pay-as-you-go dashboard</b> to supercharge your business growth.
                    </p>
                </div>
                <img src={signUpmain} alt="sign-up-main" className="sign-up-main" />
                <div className="right-wrapper">
                    <ul className="tab-view">
                        <li>
                            <Link to={SIGNINV2} className="tab-view-login">
                                Log in
                            </Link>
                        </li>
                        <li>
                            {' '}
                            <Link to={SIGNUPV2}>Create account</Link>
                        </li>
                    </ul>
                    <h3 className="signup-heading-two">Welcome back</h3>
                    <MDBox mb={2} className="spotdif-login-wrapper spotdif-signin-wrapper">
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit} className="spotdif-login-form">
                                <MDBox mb={2}>
                                    <MDInput
                                        type="email"
                                        label="Email"
                                        className="spotdif-field spotdif-field-float"
                                        onBlur={formik.handleBlur}
                                        id="email"
                                        name="email"
                                        isBordered={false}
                                        autoComplete="email"
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </MDBox>

                                <MDBox my={4}>
                                    <MDInput
                                        // type="password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        label="Password"
                                        onBlur={formik.handleBlur}
                                        className="spotdif-field spotdif-field-float"
                                        id="password"
                                        isBordered={false}
                                        autoComplete="password"
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="visibility-password-wrapper">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        className="visibility-icon-password"
                                                    >
                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </MDBox>

                                <MDTypography variant="button">
                                    <Link to="/forget-password" style={{ color: '#9FA1B3' }}>
                                        {' '}
                                        Forgotten your password?
                                    </Link>
                                </MDTypography>
                                <MDBox mt={4} mb={1}>
                                    <MDButton
                                        className="spotdif-signup-Button"
                                        type="submit"
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                    >
                                        <Icon className="spotdif-arrow">
                                            <ArrowForwardIcon />
                                        </Icon>
                                    </MDButton>
                                </MDBox>
                            </Form>
                        </FormikProvider>
                    </MDBox>
                </div>
            </div>
        </>
    );
};

export default SignIn;
