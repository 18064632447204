import reportAccepted from 'assets/images/icons/report-accepted.png';
import reportRejected from 'assets/images/icons/report-rejected.png';
import reportedLeads from 'assets/images/icons/reported-leads.png';
import validLeads from 'assets/images/icons/valid-leads.png';
import HeaderCard from 'components/shared/Card/HeaderCard';
import Pagination from 'components/shared/Pagination/Pagination';
import SpinningComponent from 'components/shared/SpinningComponent';
import TableHeaderV2 from 'components/shared/TableHeaderV2';
import { leadsAvailableStatuses } from 'contracts/app-utils/ELeadsStatus';
import { ILeadsLIstQuery } from 'contracts/requests/ILeadsListResponse';
import { LeadsBidDataSerializer } from 'contracts/spotdif/LeadsBidData';
import './style.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
    useExportClientCsvMutation,
    useGetAdminLeadsStatsQuery,
    useGetClientAllLeadsQuery,
} from 'redux/services/spotdif/leads';
import Table from './Table';
import { contactCreditEnum } from 'contracts/app-utils/ContactCredit';
import classNames from 'classnames';

const ClientLeadsTableV2: React.FC = () => {
    const [query, setQuery] = useState<ILeadsLIstQuery>({
        search: '',
        perPage: 100,
        sortingOrder: 'desc',
        leadsStatus: '',
        total: 0,
        pageCount: 0,
        page: 1,
        industry: '',
        accountManagerId: '',
        // leadType: contactCreditEnum.LIVE_CHAT_LEADS,
    });
    const [leadType, setLeadType] = useState<contactCreditEnum>(contactCreditEnum.LIVE_CHAT_LEADS);

    const { adminLeadsData, adminSerializedData, leadsLoading } = useGetClientAllLeadsQuery(
        { ...query, leadType },
        {
            selectFromResult: ({ data, isLoading }) => {
                return {
                    adminLeadsData: data,
                    adminSerializedData: data?.data?.map((item) => LeadsBidDataSerializer.parse(item)),
                    leadsLoading: isLoading,
                };
            },
        },
    );

    const { statsData } = useGetAdminLeadsStatsQuery(null, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                statsData: data?.data,
                statsLoading: isLoading,
            };
        },
    });

    useEffect(() => {
        if (adminLeadsData?.meta) {
            setQuery({
                ...query,
                pageCount: adminLeadsData?.meta?.pages,
            });
        }
    }, [adminLeadsData?.meta]);

    const [exportLeads, { data: csvData }] = useExportClientCsvMutation();
    const csvInstance: any = useRef();

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current?.link) {
            setTimeout(() => {
                csvInstance.current.link.click();
            });
        }
    }, [csvData]);

    const exportClients = () => {
        let filterUrl = `?&sortingOrder=${query.sortingOrder}&status=${query.leadsStatus}`;
        if (query.clientId) {
            filterUrl = `${filterUrl}&id=${query.clientId}`;
        }
        exportLeads(filterUrl)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const renderStatusHeader = () => (
        <>
            {adminLeadsData && (
                <div className="table_export_leads">
                    <button onClick={exportClients} className="button is-small w-button export-csv">
                        Export Leads
                    </button>
                    {csvData ? <CSVLink data={csvData} filename={'Leads.CSV'} ref={csvInstance} /> : undefined}
                </div>
            )}
        </>
    );

    const updateLeadFilters = (key: string, value: string | number) => {
        setQuery({ ...query, [key]: value, page: 1 });
    };

    const handleChangeTab = useCallback(
        (type: contactCreditEnum) => {
            setLeadType(type);
        },
        [leadType],
    );

    return (
        <>
            <SpinningComponent loading={leadsLoading}>
                <div className="layout-middle table-v2 client-leads-v2">
                    <div className="leads-tab">
                        <button
                            className={classNames({
                                'active-tab': leadType === contactCreditEnum.LIVE_CHAT_LEADS,
                            })}
                            onClick={() => handleChangeTab(contactCreditEnum.LIVE_CHAT_LEADS)}
                        >
                            Chat Leads
                        </button>
                        <button
                            className={classNames({
                                'active-tab': leadType === contactCreditEnum.INBOUND_CALL_LEADS,
                            })}
                            onClick={() => handleChangeTab(contactCreditEnum.INBOUND_CALL_LEADS)}
                        >
                            Call Leads
                        </button>
                        <button
                            className={classNames({
                                'active-tab': leadType === contactCreditEnum.HOTKEYS,
                            })}
                            onClick={() => handleChangeTab(contactCreditEnum.HOTKEYS)}
                        >
                            Hotkey Leads
                        </button>
                    </div>
                    <TableHeaderV2
                        entriesPerPage={[100, 50, 25, 10]}
                        showSorting={true}
                        leadsType={leadsAvailableStatuses}
                        updateFilters={updateLeadFilters}
                        filters={query}
                        status={renderStatusHeader()}
                    />
                    <div className="table-card-block">
                        <HeaderCard
                            icon={validLeads}
                            custClass="valid-leads"
                            value={statsData?.validLeads}
                            heading="Valid Leads"
                        />
                        <HeaderCard
                            icon={reportedLeads}
                            value={statsData?.reportedLeads}
                            custClass="reported-leads"
                            heading="Reported Leads"
                        />
                        <HeaderCard
                            icon={reportAccepted}
                            value={statsData?.reportAcceptedLeads}
                            custClass="report-accepted"
                            heading="Report Accepted"
                        />
                        <HeaderCard
                            icon={reportRejected}
                            value={statsData?.reportRejectedLeads}
                            custClass="report-rejected"
                            heading="Report Rejected"
                        />
                    </div>
                    <Table
                        loading={leadsLoading}
                        adminSerializedData={adminSerializedData}
                        query={query}
                        setQuery={setQuery}
                        adminLeadsData={adminLeadsData}
                    />
                    {adminLeadsData?.meta && (
                        <Pagination
                            totalCount={adminLeadsData?.meta?.total}
                            currentPage={
                                typeof adminLeadsData?.meta?.page === 'string'
                                    ? parseInt(adminLeadsData?.meta?.page)
                                    : adminLeadsData?.meta?.page * 1
                            }
                            pageSize={adminLeadsData?.meta?.perPage}
                            onPageChange={(page) => setQuery({ ...query, page })}
                        />
                    )}
                </div>
            </SpinningComponent>
        </>
    );
};

export default ClientLeadsTableV2;
