import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { InputAdornment, MenuItem, Select, Tooltip } from '@mui/material';
import fileUploadIcon from 'assets/images/small-logos/fileUploadIcon.svg';
import classNames from 'classnames';
import AddressLookUp from 'components/shared/AddressLookup';
import BusinessIndustryPicker from 'components/shared/BusinessIndustryPicker';
import BusinessOpeningHours from 'components/shared/BusinessOpeningHours';
import BuyerAnswers from 'components/shared/BuyerAnswers';
import MDBox from 'components/themed/MDBox';
import MDInput from 'components/themed/MDInput';
import { useCountryCodeContext } from 'context/CountryCodeContext/CountryCodeContext';
import { IBusinessDetailsRequest } from 'contracts/requests/IBusinessDetailsRequest';
import { ECountry, ECurrency } from 'contracts/spotdif/currencyAndCountryCodes';
import { useFormikContext } from 'formik';
import useAddressSetter from 'hooks/useAddressSetter';
import useAuthentication from 'hooks/useAuthentication';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useGetMobilePrefixQuery } from 'redux/services/spotdif';

const BusinessInfo: React.FC = () => {
    const { user } = useAuthentication();
    const { businessDetailsId } = user;

    const [file, setFile] = useState<File | string>(user?.businessDetailsId?.businessLogo || '');
    const [isManualLocation, toggleManualLocation] = useState(false);
    const { data: mobilePrefixList } = useGetMobilePrefixQuery();
    const { currentCountryCode } = useCountryCodeContext();

    const { values, handleBlur, handleChange, touched, errors, setFieldValue } =
        useFormikContext<IBusinessDetailsRequest>();

    const { setAddressByCountry } = useAddressSetter();

    const setAddress = useCallback(
        (address) => {
            setAddressByCountry(setFieldValue, address);
        },
        [values.businessAddress, setAddressByCountry],
    );

    const removeImageHandler = () => {
        setFile('');
        setFieldValue('businessLogo', '');
    };

    const renderPreview = () => {
        if (file instanceof File) {
            return (
                <MDBox className="uploaded-image" onClick={removeImageHandler}>
                    <img src={URL.createObjectURL(file)} alt="uploaded preview" width={100} />
                    <MDBox className="deleteIcon">
                        <CloseIcon />
                    </MDBox>
                </MDBox>
            );
        } else if (typeof file === 'string' && file) {
            return (
                <MDBox className="uploaded-image " onClick={removeImageHandler}>
                    <img
                        src={process.env.REACT_APP_API_SERVER + businessDetailsId?.businessLogo}
                        alt="uploaded preview this one"
                        width={100}
                    />
                    <MDBox className="deleteIcon">
                        <CloseIcon />
                    </MDBox>
                </MDBox>
            );
        }
    };

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
        },
        maxSize: 5242880,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setFieldValue('businessLogo', acceptedFiles[0]);
                setFile(acceptedFiles[0]);
            }
        },
    });

    useEffect(() => {
        if (errors.addressLine1 || errors.addressLine2 || errors.city || errors.postcode) {
            toggleManualLocation(true);
        }
    }, [errors]);

    return (
        <div className="tab-user-content-card">
            <div className="form-middle form-upper">
                <div className="form-col-2">
                    <BusinessIndustryPicker fieldName="businessIndustry" />
                    <MDInput
                        type="text"
                        label="Business Name"
                        onBlur={handleBlur}
                        id="businessName"
                        name="businessName"
                        value={values.businessName}
                        autoComplete="businessName"
                        placeholder="Business Name"
                        onChange={handleChange}
                        error={touched.businessName && Boolean(errors.businessName)}
                        helperText={touched.businessName && errors.businessName}
                        disabled={user?.hasOnboarded}
                        className={classNames({
                            'disabled-input-with-icon': user?.hasOnboarded,
                        })}
                        InputProps={
                            user?.hasOnboarded && {
                                endAdornment: (
                                    <InputAdornment position="end" className="input-adorement">
                                        <Tooltip title="Contact the support team at Leads.spotdif.com" placement="top">
                                            <ErrorIcon className="info-content-icon" />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }
                        }
                    />

                    <div className="position-relative address-input">
                        <AddressLookUp
                            onChange={handleChange}
                            handlePlaceSelect={setAddress}
                            name="businessAddress"
                            className="googleapi"
                            type="text"
                            label="Business Address"
                            onBlur={handleBlur}
                            id="businessAddress"
                            value={values.businessAddress}
                            autoComplete="businessAddress"
                            placeholder="Business Address"
                        />
                        <span className="form-input-label" onClick={() => toggleManualLocation(!isManualLocation)}>
                            {!isManualLocation ? 'Enter address manually' : 'Enter address automatically'}
                        </span>
                    </div>
                    <MDInput
                        type="tel"
                        label="Business Phone Number"
                        onBlur={handleBlur}
                        value={values.businessPhoneNumber}
                        id="businessPhoneNumber"
                        name="businessPhoneNumber"
                        autoComplete="businessPhoneNumber"
                        placeholder="Business Phone Number"
                        onChange={handleChange}
                        error={touched.businessPhoneNumber && Boolean(errors.businessPhoneNumber)}
                        helperText={touched.businessPhoneNumber && errors.businessPhoneNumber}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Select
                                        value={values.businessMobilePrefixCode}
                                        name="businessMobilePrefixCode"
                                        onChange={handleChange}
                                        MenuProps={{
                                            style: {
                                                maxHeight: '300px',
                                            },
                                            anchorOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                        }}
                                        className="input-adornment-select-mobile-prefix-business"
                                        renderValue={(selected) => {
                                            const selectedCountry = mobilePrefixList?.find(
                                                (country) => country?.mobilePrefixCode === selected,
                                            );
                                            return selectedCountry ? `+${selectedCountry?.mobilePrefixCode}` : '';
                                        }}
                                    >
                                        {mobilePrefixList?.map((country) => (
                                            <MenuItem
                                                key={country?.label}
                                                value={country?.mobilePrefixCode}
                                                autoFocus={
                                                    values.businessMobilePrefixCode === country?.mobilePrefixCode
                                                }
                                            >
                                                {`+${country?.mobilePrefixCode} - ${country?.label}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {isManualLocation && (
                        <>
                            <MDInput
                                type="text"
                                label="Address Line 1"
                                isBordered="true"
                                placeholder="Address Line 1"
                                onBlur={handleBlur}
                                id="addressLine1"
                                name="addressLine1"
                                value={values.addressLine1}
                                autoComplete="addressLine1"
                                onChange={handleChange}
                                error={touched.addressLine1 && Boolean(errors.addressLine1)}
                                helperText={touched.addressLine1 && errors.addressLine1}
                            />

                            <MDInput
                                type="text"
                                label="Address Line 2"
                                placeholder="Address Line 2"
                                onBlur={handleBlur}
                                value={values.addressLine2}
                                id="addressLine2"
                                name="addressLine2"
                                autoComplete="addressLine2"
                                onChange={handleChange}
                                error={touched.addressLine2 && Boolean(errors.addressLine2)}
                                helperText={touched.addressLine2 && errors.addressLine2}
                            />

                            <MDInput
                                type="text"
                                label="City"
                                placeholder="City"
                                onBlur={handleBlur}
                                id="city"
                                value={values.city}
                                name="city"
                                autoComplete="city"
                                onChange={handleChange}
                                error={touched.city && Boolean(errors.city)}
                                helperText={touched.city && errors.city}
                            />
                            <MDInput
                                type="text"
                                label={
                                    currentCountryCode === ECountry.US || user?.currency === ECurrency.USD
                                        ? 'Zipcode'
                                        : 'Postcode'
                                }
                                placeholder={
                                    currentCountryCode === ECountry.US || user?.currency === ECurrency.USD
                                        ? 'Zipcode'
                                        : 'Postcode'
                                }
                                onBlur={handleBlur}
                                id="postcode"
                                value={values.postcode}
                                name="postcode"
                                autoComplete="postcode"
                                onChange={handleChange}
                                error={touched.postcode && Boolean(errors.postcode)}
                                helperText={touched.postcode && errors.postcode}
                            />
                        </>
                    )}

                    <div className="full-width-field">
                        <MDInput
                            type="text"
                            placeholder="https://spotdif.com"
                            label="Business URL"
                            onBlur={handleBlur}
                            id="businessUrl"
                            value={values.businessUrl ?? ''}
                            name="businessUrl"
                            autoComplete="businessUrl"
                            onChange={handleChange}
                            error={touched.businessUrl && Boolean(errors.businessUrl)}
                            helperText={touched.businessUrl && errors.businessUrl}
                        />
                    </div>

                    <div className="full-width-field">
                        <MDInput
                            type="text"
                            placeholder="Business Description"
                            label="Business Description"
                            onBlur={handleBlur}
                            id="businessDescription"
                            value={values.businessDescription ?? ''}
                            name="businessDescription"
                            autoComplete="businessDescription"
                            onChange={handleChange}
                            error={touched.businessDescription && Boolean(errors.businessDescription)}
                            helperText={touched.businessDescription && errors.businessDescription}
                            multiline
                            rows={5}
                        />
                    </div>

                    <BuyerAnswers />

                    <div className="business-logo-hrs">
                        <MDBox mb={2} className="spotdif-field w-50">
                            <div className="form-group">
                                <label>Business Hours</label>
                                <BusinessOpeningHours isHoursVisible={true} />
                                {errors.businessOpeningHours?.length > 0 && touched.businessOpeningHours && (
                                    <p className="error-text input-error-text">At least one day is required.</p>
                                )}
                            </div>
                        </MDBox>
                        <MDBox mb={2} className="w-50 spotdif-field">
                            <div className="form-group ">
                                <label>Business Logo</label>
                                <div>
                                    {renderPreview()}
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p className="button button-outline-primary w-50">
                                                Drop the files here ...
                                            </p>
                                        ) : (
                                            <p className="button w-50">
                                                Upload new logo
                                                {/* <img
                                                    className="upload-image-icon"
                                                    src={fileUploadIcon}
                                                    alt="upload icon "
                                                /> */}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {errors.businessLogo && touched.businessLogo ? (
                                <MDBox width="100%" display="flex" justifyContent="left" color="red">
                                    <p className="error-text input-error-text">Business logo is required</p>
                                </MDBox>
                            ) : null}
                            {/* {dropzoneValidation > 5242880 ? (
                                            <MDBox width="100%" display="flex" justifyContent="center" color="red">
                                                Logo is too big, Maximum size should be 5MB
                                            </MDBox>
                                        ) : null} */}

                            {file instanceof File && file.size > 5242880 ? (
                                <MDBox width="100%" display="flex" justifyContent="center" color="red">
                                    Logo is too big, Maximum size should be 5MB
                                </MDBox>
                            ) : null}
                        </MDBox>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessInfo;
