import { UserBase } from '../spotdif/UserBase';
import { IMessageOnlyResponse } from './IMessageOnlyResponse';

export interface IBaseUserInformation {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface IRegisterUserRequest extends IBaseUserInformation {
    confirmPassword: string;
    recaptcha?: string;
}

export interface IUserLoginRequest {
    email: string;
    password: string;
}

export interface IUserAuthErrorResponse {
    data: IMessageOnlyResponse;
    message?: string;
}

export function isUserAuthErrorResponse(obj: any): obj is IUserAuthErrorResponse {
    return !!obj && !!(obj as IUserAuthErrorResponse).data;
}

export interface IUserRegisterResponse {
    data: UserBase;
    token: string;
    message?: string;
}

export function isUserLoginResponse(obj: any): obj is IUserRegisterResponse {
    return !!(obj as IUserRegisterResponse).token;
}

export interface IMobilePrefixResponse {
    mobilePrefixCode: string;
    label: string;
    countryCode: string;
}
