import DiamondIcon from '@mui/icons-material/Diamond';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import BannerWithoutButton from 'components/shared/OfferBanner/BannerWithoutButton';
import SpotDifSupplier from 'components/shared/SpotdifSupplier';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import './style.scss';
import { useGetSupplierBadgesQuery, useSetPartnerUrlMutation } from 'redux/services/spotdif/partners';
import { useGetBadgeStatusQuery } from 'redux/services/spotdif/user';
import * as Yup from 'yup';

const ClientCertifiedSupplier: React.FC = () => {
    const { data: getBadgeStatus } = useGetBadgeStatusQuery();

    const { data: getSupplierBadgeData } = useGetSupplierBadgesQuery();

    const [setPartnerUrl] = useSetPartnerUrlMutation();

    const { urlRegex } = useRegexPatterns();

    const initialValues = {
        confirmBadge: false,
        supplierUrl: '',
    };

    const validationSchema = Yup.object().shape({
        supplierUrl: Yup.string().when('confirmBadge', {
            is: true,
            then: (schema) => schema.required('Please enter your URL *').matches(urlRegex, 'Enter a valid URL'),
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setPartnerUrl({ website: values.supplierUrl })
                .unwrap()
                .then((data) => {
                    return enqueueSnackbar(data?.message || "We'll check and update your balance shortly.", {
                        variant: 'success',
                        key: 'supplier-url',
                    });
                })
                .catch((err) => {
                    return enqueueSnackbar(err?.data?.error?.message || 'Something went wrong.', {
                        variant: 'error',
                        key: 'supplier-url',
                    });
                });
        },
    });

    useEffect(() => {
        if (getBadgeStatus?.data?.length > 0) {
            formik.setFieldValue('confirmBadge', true);
            formik.setFieldValue('supplierUrl', getBadgeStatus?.data[0]?.link);
        }
    }, [getBadgeStatus, formik]);

    return (
        <div className="layout-middle">
            <div className="bg-white p-01 br-2">
                <BannerWithoutButton title="<b>Get 5 free leads</b> when you become a certified supplier today" />
                {/* <h1 className="mt-2 supplier-heading-text">
                    <span className="icon-size mr-1">
                        <DiamondIcon />
                    </span>
                    Join <span className="text-orange">over 2,000</span> SpotDif certified suppliers
                </h1> */}
                {/* mt-1 */}
                <p className="mt-2 font-weight-light"> 
                    Add the SpotDif badge, banner, or blog post to your website and become a SpotDif certified supplier,
                    aligning with sptdif supplier and other trusted businesses. Boost your credibility and showcase the
                    SpotDif seal of approval whilst gaining access to five free leads in the process!
                </p>
                <div className="mt-2">
                    <span className="text-bold">How?</span> You have 3 options:
                </div>
            </div>

            {getSupplierBadgeData
                ? getSupplierBadgeData?.badges?.map((badge) => (
                      <SpotDifSupplier
                          contentTitle={badge.contentTitle}
                          key={badge._id}
                          type={badge.type}
                          title={badge.title}
                          imageUrl={badge.imageUrl}
                          codeSnippet={badge.codeSnippet}
                      />
                  ))
                : null}

            {getSupplierBadgeData?.badges?.length === 0 && (
                <div className="no_data_box mt-2">
                    <h6>There are no certificates to be offered.</h6>
                </div>
            )}

            <div className="confirm-badge-wrapper">
                <MDTypography variant="h3" className="confirm-badge-heading">
                    What happens next?
                </MDTypography>
                <div className="confirm-badge-text-wrapper">
                    <MDTypography variant="p" className="confirm-badge-text">
                        Once you've added one of the options above to your site, let us know by clicking below.
                    </MDTypography>
                    <MDTypography variant="p" className="confirm-badge-text">
                        Once verified, we'll credit your account with your<b> 5 leads free of charge.</b>
                    </MDTypography>
                </div>

                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit} className="">
                        <div
                            className={classNames('confirm-checkbox-wrapper', {
                                'confirm-checkbox-wrapper-checked': formik.values.confirmBadge,
                            })}
                        >
                            <Checkbox
                                color="success"
                                className="checkbox-border"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.confirmBadge}
                                name="confirmBadge"
                                id="confirmBadge"
                                disabled={
                                    getBadgeStatus?.data[0]?.status === 'Removed' ||
                                    getBadgeStatus?.data[0]?.status === 'Active'
                                }
                            />
                            <MDTypography
                                className={classNames('confirm-checkbox-text', {
                                    'confirm-checkbox-text-checked': formik.values.confirmBadge,
                                })}
                            >
                                Done, I've added it!
                            </MDTypography>
                        </div>
                        {formik.values.confirmBadge && (
                            <div className="confirm-badge-input-wrapper">
                                <MDInput
                                    className="confirm-badge-input"
                                    type="text"
                                    // label="Supplier"
                                    placeholder="Your site URL"
                                    value={formik.values.supplierUrl}
                                    id="supplierUrl"
                                    autoComplete="supplierUrl"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.supplierUrl && Boolean(formik.errors.supplierUrl)}
                                    helperText={formik.touched.supplierUrl && formik.errors.supplierUrl}
                                    fullWidth
                                    disabled={
                                        getBadgeStatus?.data[0]?.status === 'Removed' ||
                                        getBadgeStatus?.data[0]?.status === 'Active'
                                    }
                                />
                                <MDButton
                                    className="button confirm-badge-input-save"
                                    type="submit"
                                    disabled={
                                        getBadgeStatus?.data[0]?.status === 'Removed' ||
                                        getBadgeStatus?.data[0]?.status === 'Active'
                                    }
                                >
                                    Save{' '}
                                </MDButton>
                            </div>
                        )}
                    </Form>
                </FormikProvider>
            </div>
        </div>
    );
};

export default ClientCertifiedSupplier;
